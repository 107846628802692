.form-demo .card {
    min-width: 450px;
    max-width: 800px;
}
.form-demo .card form {
    margin-top: 2rem;
}
.form-demo .card .field {
    margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
    .form-demo .card {
        width: 80%;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 0;
    }
}

@media screen and (max-width: 788px) {
    .grid {
        display: block;
        margin-right: 0;
        margin-left: 0;
        margin-top: 0;
    }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}